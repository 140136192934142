import React from "react";
import { useNavigate } from "react-router-dom";
import ExpertRegisterForm from "../components/ExpertRegisterForm";
import { useAppState } from "../context/AppStateContext";

const RegisterCopilotExpert = () => {
  const { state, dispatch } = useAppState();
  const searchBarParams = new URLSearchParams(window.location.search);
  const redirectURL = searchBarParams.get("redirect");

  const navigate = useNavigate();

  const handleRedirectToLogin = () => {
    if (
      redirectURL !== null &&
      redirectURL !== undefined &&
      redirectURL !== ""
    ) {
      const params = window.location.href.split("?")[1];
      navigate(`/?${params}`);
    } else {
      navigate("/");
    }
  };

  return (
    <div className="w-full max-w-[355px] px-1  Login--form ">
      <button
        className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-bold transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 hover:bg-slate-100 hover:text-accent-foreground h-9 px-4 py-2 absolute right-4 top-4 md:right-8 md:top-8 text-primary-500"
        onClick={handleRedirectToLogin}
      >
        Login
      </button>

      <img
        src={state?.appLogoUrl || process.env.REACT_APP_UWC_LOGO}
        alt="logo"
        width={150}
        className="mx-auto mb-4 xl:hidden"
      />
      <h1 className="text-2xl font-semibold tracking-tight text-center">
        Create Account
      </h1>
      {/* <p className="text-sm text-slate-500 font-medium text-center">
          Sign up with your email address
        </p> */}

      <ExpertRegisterForm />
    </div>
  );
};

export default RegisterCopilotExpert;
