import { useNavigate } from "react-router-dom";
import { useAppState } from "../../context/AppStateContext";

const RegisterButton = ({ redirectURL, type }: any) => {
  const { state, dispatch } = useAppState();
  const navigate = useNavigate();

  const handleRedirectToRegister = () => {
    const params = window.location.href.split("?")[1];
    const hasRedirectURL =
      redirectURL !== null && redirectURL !== undefined && redirectURL !== "";
    if (window.ReactNativeWebView) {
      navigate(
        hasRedirectURL
          ? `/register/user-register/?${params}`
          : "/register/user-register/"
      );
    } else {
      if(state?.ipCountry == "AU"){
        navigate(hasRedirectURL ? `/register/copilot-register?${params}` : "/register/copilot-register");
      }else{
        navigate(hasRedirectURL ? `/register?${params}` : "/register");
      }
      
    }
  };
  return (
    <>
      {type == "FLOATING" ? (
        <button
          className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-bold transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 hover:bg-slate-100 hover:text-accent-foreground h-9 px-4 py-2 absolute right-4 top-4 md:right-8 md:top-8 text-primary-500"
          onClick={handleRedirectToRegister}
        >
          Register
        </button>
      ) : (
        <button className="text-primary-500" onClick={handleRedirectToRegister}>
          Create one now
        </button>
      )}
    </>
  );
};

export default RegisterButton;
